import React, { FC } from 'react'
import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import useSiteMetadata from '@talentinc/gatsby-theme-ecom/hooks/useSiteMetadata'
import resumeImg from '@talentinc/gatsby-theme-ecom/images/resume.svg'
import resumeImgFr from '@talentinc/gatsby-theme-ecom/images/resume-fr.svg'
import WarningSvg from '@talentinc/gatsby-theme-ecom/images/warning-red.inline.svg'
import CheckSvg from '@talentinc/gatsby-theme-ecom/images/checkmarkblue.inline.svg'

const HeroBanner: FC = () => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const {
    display_name: brandName,
    default_resume_product: defaultResumeProduct = '',
  } = useBrand()
  const { locale } = useSiteMetadata()

  const defaultResumeProductLowercase = defaultResumeProduct.toLowerCase()

  return (
    <Box className={classes.heroBanner}>
      <Box className={classes.content}>
        <Box className={cx(classes.header, classes.headerlarge)}>
          <Typography className={classes.headerText}>
            {t('accountHistory.heroBanner.header')}
          </Typography>
        </Box>
        <Typography className={classes.title}>
          {t('accountHistory.heroBanner.title')}
        </Typography>
        <Typography className={classes.description}>
          {t('accountHistory.heroBanner.description1', {
            brandName,
            defaultResumeProduct,
          })}
        </Typography>
        <Typography className={classes.description}>
          {t('accountHistory.heroBanner.description2', {
            defaultResumeProduct,
          })}
        </Typography>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <CheckSvg />
            <Typography className={classes.listItemText}>
              {t('accountHistory.heroBanner.listItem1')}
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <CheckSvg />
            <Typography className={classes.listItemText}>
              {t('accountHistory.heroBanner.listItem2')}
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <CheckSvg />
            <Typography className={classes.listItemText}>
              {t('accountHistory.heroBanner.listItem3')}
            </Typography>
          </ListItem>
        </List>
        <Button
          variant="contained"
          color="primary"
          className={classes.mainButton}
          href={t('accountHistory.slugs.resumeWriting', {
            defaultResumeProductLowercase,
          })}
        >
          {t('accountHistory.heroBanner.learnMore')}
        </Button>
      </Box>
      <Box className={classes.imgContainer}>
        <img
          className={classes.img}
          src={locale === 'fr' ? resumeImgFr : resumeImg}
          alt="doc"
        />
        <Box>
          <WarningSvg />
          <Typography className={classes.warningText}>
            {t('accountHistory.heroBanner.lowResumeScore')}
          </Typography>
        </Box>
      </Box>
      <Box className={cx(classes.header, classes.headerSmall)}>
        <Typography className={classes.headerText}>
          {t('accountHistory.heroBanner.header')}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  heroBanner: {
    backgroundColor: theme.colors.blue[10],
    display: 'flex',
    width: '100%',
    borderRadius: '12px',
    justifyContent: 'space-between',
    gap: '2rem',
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      flexDirection: 'column-reverse',
      gap: 0,
      height: 'auto',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2.5rem 3rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: '1.25rem',
    },
  },
  header: {
    backgroundColor: theme.colors.blue[250],
    borderRadius: '4px',
    padding: '0.125rem 0.5rem',
    alignSelf: 'flex-start',
    marginBottom: '1.375rem',
  },
  headerText: {
    color: theme.colors.blue[80],
    fontFamily: 'tt-talent',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '20px',
  },
  headerlarge: {
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      display: 'none',
    },
  },
  headerSmall: {
    margin: '1.5rem 3rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: '1.25rem',
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
      display: 'none',
    },
  },
  title: {
    color: theme.colors.neutral[90],
    fontFamily: 'tt-talent',
    fontWeight: 500,
    fontSize: '1.6875rem',
    lineHeight: '28px',
    marginBottom: '0.25rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1.1875rem',
    },
  },
  description: {
    color: theme.colors.neutral[90],
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: '1.1875rem',
    lineHeight: '24px',
    marginTop: '1rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1rem',
    },
  },
  list: {
    marginBottom: '1rem',
  },
  listItem: {
    display: 'flex',
    padding: '0px',
    alignItems: 'flex-start',
  },
  listItemText: {
    flex: 1,
    color: theme.colors.neutral[90],
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: '1.1875rem',
    lineHeight: '24px',
    marginLeft: '0.25rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1rem',
    },
  },
  mainButton: {
    fontSize: '1.1875rem',
    padding: '0.75rem 1.5rem',
    fontFamily: 'tt-talent',
    letterSpacing: '0px',
    width: 'fit-content',
    lineHeight: '24px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1rem',
    },
  },
  imgContainer: {
    display: 'flex',
    alignSelf: 'center',
    marginRight: '2rem',
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      marginRight: 0,
    },
    [theme.breakpoints.up(theme.breakpoints.values.desktop)]: {
      marginRight: '4rem',
    },
  },
  img: {
    marginRight: '1rem',
  },
  warningText: {
    color: theme.colors.blue[80],
    fontFamily: 'tt-talent',
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '16px',
    width: 'min-content',
  },
}))

export default HeroBanner
