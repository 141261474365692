import React, { FC, ReactNode } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import useSiteMetadata from '@talentinc/gatsby-theme-ecom/hooks/useSiteMetadata'
import resumeImage from '@talentinc/gatsby-theme-ecom/images/resume-checks-blue.svg'
import resumeImageFr from '@talentinc/gatsby-theme-ecom/images/resume-checks-blue-fr.svg'

interface ResumeReviewProps {
  title: string
  primaryContent: string | ReactNode
  secondaryContent?: string | ReactNode
  primaryButtonHref?: string
  secondaryButtonHref?: string
  primaryButtonText?: string
  secondaryButtonText?: string
  containerClass?: string
  imgSrc?: string
}

const ResumeReview: FC<ResumeReviewProps> = ({
  title,
  primaryContent,
  secondaryContent,
  primaryButtonHref,
  secondaryButtonHref,
  primaryButtonText,
  secondaryButtonText,
  containerClass,
  imgSrc,
}) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation()
  const { default_resume_product: defaultResumeProduct } = useBrand()
  const { locale } = useSiteMetadata()

  return (
    <Box className={cx(classes.container, containerClass)}>
      <Box className={classes.content}>
        <Box className={classes.contentTop}>
          <Typography className={classes.header}>
            {t('accountHistory.resumeReview.header', { defaultResumeProduct })}
          </Typography>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.description}>
            {primaryContent}
          </Typography>
        </Box>
        <Box className={classes.contentBottom}>
          {secondaryContent && (
            <Typography className={classes.description}>
              {secondaryContent}
            </Typography>
          )}
          <Box className={classes.actionContainer}>
            {primaryButtonText && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                href={primaryButtonHref}
              >
                {primaryButtonText}
              </Button>
            )}
            {secondaryButtonText && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonSecondary}
                href={secondaryButtonHref}
              >
                {secondaryButtonText}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <img
        className={classes.img}
        src={imgSrc ?? (locale === 'fr' ? resumeImageFr : resumeImage)}
        alt="doc"
      />
    </Box>
  )
}

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.colors.blue[10],
    display: 'flex',
    width: '100%',
    borderRadius: '12px',
    justifyContent: 'space-between',
    gap: '2rem',

    minHeight: '296px',
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      flexDirection: 'column-reverse',
      gap: 0,
      height: 'auto',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2.5rem 3rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: '1.25rem',
    },
  },
  contentTop: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentBottom: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
  },
  header: {
    color: theme.colors.neutral[60],
    fontFamily: 'tt-talent',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '20px',
    marginBottom: '1.25rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: '0.5rem',
      fontSize: '0.75rem',
    },
  },
  title: {
    color: theme.colors.neutral[90],
    fontFamily: 'tt-talent',
    fontWeight: 500,
    fontSize: '1.6875rem',
    lineHeight: '28px',
    marginBottom: '1.25rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: '0',
      fontSize: '1.1875rem',
    },
  },
  description: {
    color: theme.colors.neutral[60],
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: '1.1875rem',
    lineHeight: '24px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1rem',
    },
  },
  actionContainer: {
    display: 'flex',
    gap: '0.75rem',
    marginTop: '1rem',
    [theme.breakpoints.down(theme.breakpoints.values.desktop)]: {
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      flexDirection: 'column',
    },
  },
  button: {
    fontSize: '1.1875rem',
    padding: '0.75rem 1.5rem',
    fontFamily: 'tt-talent',
    letterSpacing: '0px',
    width: 'fit-content',
    lineHeight: '24px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1rem',
    },
  },
  buttonSecondary: {
    fontSize: '1.1875rem',
    padding: '0.75rem 1.5rem',
    fontFamily: 'tt-talent',
    letterSpacing: '0px',
    width: 'fit-content',
    lineHeight: '24px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1rem',
    },
  },
  img: {
    alignSelf: 'center',
    padding: '2rem 1rem',
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      width: '100%',
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: '0 1rem',
    },
  },
}))

export default ResumeReview
