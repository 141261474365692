import { format as dateFormat, parseISO } from 'date-fns'

/**
 * formatDate is a wrapper function for `date-fns/format` that allows passing of
 * string dates, in addition to numbers and Date objects. If a string is passed,
 * it is run through `date-fns/parseISO` before formatting.
 *
 * https://date-fns.org/v2.17.0/docs/format
 * https://date-fns.org/v2.17.0/docs/parseISO
 */
export const formatDate = (
  date: string | number | Date,
  format: string,
  options?: Parameters<typeof dateFormat>[2]
): string => {
  if (typeof date === 'string') {
    date = parseISO(date)
  }

  return dateFormat(date, format, options)
}

export default formatDate
