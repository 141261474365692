import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import BaseAccountHistoryTemplate from '@talentinc/gatsby-theme-ecom/components/AccountHistory/index'
import { PageDataContextType } from '@talentinc/gatsby-theme-ecom/types/page'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import { AccountHistory } from '@talentinc/gatsby-theme-ecom/types/accountHistory'

type Props = PageProps<
  {
    accountHistory: AccountHistory
  } & PageDataContextType
>

const AccountHistoryTemplate: React.FC<Props> = (props) => {
  const { accountHistory, ...pageData } = props.data

  return (
    <Layout pageTitle={accountHistory.title} pageData={pageData}>
      <HeaderV2 />
      <BaseAccountHistoryTemplate accountHistory={accountHistory} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query AccountHistoryBySlugAndBrand($slug: String!, $brandName: String!) {
    accountHistory: contentfulAccountHistory(
      slug: { eq: $slug }
      node_locale: { eq: "en-GB" }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...AccountHistory
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default AccountHistoryTemplate
