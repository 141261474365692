import React, { FC } from 'react'
import { Box, Divider, Typography, Skeleton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import SvgCheck from '@talentinc/gatsby-theme-ecom/images/check-circle-blue.inline.svg'
import SvgCircle from '@talentinc/gatsby-theme-ecom/images/empty-circle.inline.svg'
import useBrand from '@talentinc/gatsby-theme-ecom/hooks/useBrand'
import useSiteMetadata from '@talentinc/gatsby-theme-ecom/hooks/useSiteMetadata'
import useUser from '@talentinc/gatsby-theme-ecom/hooks/useUser'
import Link from '../Link'

interface SidebarProps {
  email: string
  name: string | null | undefined
  shouldShowRevisitResume: boolean
  resumeUploaded: boolean
  critiqueViewed: boolean
}

const Sidebar: FC<SidebarProps> = ({
  email,
  name,
  shouldShowRevisitResume,
  resumeUploaded,
  critiqueViewed,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const {
    display_name: brandName,
    default_resume_product: defaultResumeProduct = '',
    isZipJob,
  } = useBrand()
  const { locale } = useSiteMetadata()
  const { lead_auth_token: leadAuthToken } = useUser()

  const defaultResumeProductLowercase = defaultResumeProduct.toLowerCase()

  return (
    <Box className={classes.sideBar}>
      <Box className={classes.box}>
        <Typography className={classes.name}>
          {`${t('accountHistory.sidebar.hi')}, ${name ?? 'there'}`}
        </Typography>
        {!email ? (
          <Skeleton animation="pulse" className={classes.emailLoader} />
        ) : (
          <Typography className={classes.email}>{email}</Typography>
        )}
      </Box>
      <Divider />
      <Box className={classes.box}>
        <Step
          checked
          text={t('accountHistory.sidebar.createAccount', { brandName })}
          navigateTo={t('accountHistory.slugs.accountHistory')}
        />
        <Step
          checked={resumeUploaded}
          text={t('accountHistory.sidebar.uploadResume', {
            defaultResumeProduct,
          })}
          navigateTo={t('accountHistory.slugs.resumeReview', {
            defaultResumeProductLowercase,
          })}
        />
        {shouldShowRevisitResume && (
          <Step
            checked={critiqueViewed}
            text={t('accountHistory.sidebar.readResumeReview', {
              defaultResumeProduct,
            })}
            navigateTo={`${t('accountHistory.slugs.resumeCritiqueView', {
              defaultResumeProductLowercase,
            })}${leadAuthToken ? `?at=${leadAuthToken}` : ''}`}
          />
        )}
        <Step
          text={t('accountHistory.sidebar.upgradeResume', {
            defaultResumeProduct,
          })}
          navigateTo={t('accountHistory.slugs.resumeWriting', {
            defaultResumeProductLowercase,
          })}
        />
        {locale === 'fr' || isZipJob ? (
          <Step
            text={t('accountHistory.sidebar.linkedinService')}
            navigateTo={t('accountHistory.slugs.linkedinProfileServices')}
          />
        ) : (
          <Step
            text={t('accountHistory.sidebar.refineYourPitch')}
            navigateTo="https://www.topinterview.com"
            target="_blank"
          />
        )}
      </Box>
    </Box>
  )
}

interface StepProps {
  text: string
  navigateTo?: string
  checked?: boolean
  target?: JSX.IntrinsicElements['a']['target']
}

const Step: FC<StepProps> = ({
  checked,
  text,
  navigateTo,
  target = '_self',
}) => {
  const { classes } = useStyles()

  return (
    <Link className={classes.step} href={navigateTo} target={target}>
      {checked ? (
        <SvgCheck className={classes.icon} />
      ) : (
        <SvgCircle className={classes.icon} />
      )}
      <Typography className={classes.stepText}>{text}</Typography>
    </Link>
  )
}

const useStyles = makeStyles()((theme) => ({
  sideBar: {
    width: '248px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: 'none',
    },
  },
  box: {
    padding: '0.5rem 0',
  },
  name: {
    color: theme.colors.neutral[90],
    fontFamily: 'tt-talent',
    fontWeight: 500,
    fontSize: '2.0625rem',
    lineHeight: '36px',
    marginBottom: '0.5rem',
  },
  email: {
    color: theme.colors.neutral[60],
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '20px',
    marginBottom: '1.5rem',
    wordWrap: 'break-word',
  },
  emailLoader: {
    width: '100%',
    height: 20,
    marginBottom: '1.5rem',
  },
  step: {
    display: 'flex',
    margin: '1.5rem 0',
  },
  stepText: {
    color: theme.colors.neutral[90],
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: '1.1875rem',
    lineHeight: '24px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1rem',
    },
  },
  icon: {
    marginRight: '0.75rem',
  },
}))

export default Sidebar
