import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import SvgArrow from '@talentinc/gatsby-theme-ecom/images/arrow.inline.svg'
import Link from '../Link'

export type CTABannerProps = {
  title: string
  description: string
  imageSrc: string
  imageContainerClass?: string
  navigateTo?: string
  target?: JSX.IntrinsicElements['a']['target']
}

const CTABanner: FC<CTABannerProps> = ({
  title,
  description,
  imageSrc,
  imageContainerClass,
  navigateTo,
  target = '_self',
}) => {
  const { classes, cx } = useStyles()

  return (
    <Link href={navigateTo} target={target} className={classes.ctaBanner}>
      <Box className={cx(classes.imgBox, imageContainerClass)}>
        <img className={classes.img} alt={title} src={imageSrc} />
      </Box>
      <Box className={classes.textBox}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
      <Box className={classes.iconBox}>
        <SvgArrow className={classes.icon} />
      </Box>
    </Link>
  )
}

const useStyles = makeStyles<void, 'icon'>()((theme, _, classes) => ({
  ctaBanner: {
    backgroundColor: theme.colors.neutral[5],
    display: 'flex',
    width: '100%',
    borderRadius: '16px',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
    },
    '&:hover': {
      backgroundColor: theme.colors.neutral[10],
      [`& ${classes.icon}`]: {
        '& rect': {
          fill: theme.colors.neutral[50],
        },
        '& path': {
          fill: theme.colors.white[100],
        },
      },
    },
  },
  imgBox: {
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    minHeight: '136px',
    width: '196px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%',
      height: '202px',
    },
    '&&': {
      height: 'auto',
      aspectRatio: '5/3',
      maxHeight: 'none',
      maxWidth: 'none',
    },
  },
  img: {
    width: '100%',
  },
  textBox: {
    alignSelf: 'center',
    paddingRight: '5.5rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: '1.5rem',
      paddingRight: '5.5rem',

      alignSelf: 'flex-start',
    },
  },
  title: {
    color: theme.colors.neutral[80],
    fontFamily: 'tt-talent',
    fontWeight: 500,
    fontSize: '1.4375rem',
    lineHeight: '28px',
    marginBottom: '0.5rem',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1.1875rem',
    },
  },
  description: {
    color: theme.colors.neutral[60],
    fontFamily: 'tt-talent',
    fontWeight: 400,
    fontSize: '1.1875rem',
    lineHeight: '24px',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: '1rem',
    },
  },
  iconBox: {
    padding: '1.5rem',
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  icon: {},
}))

export default CTABanner
